import React from "react";
import Skills from "../Components/Skills";

export default function ResumePage() {
  return (
    <div>
      <Skills />
    </div>
  );
}
